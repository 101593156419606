/**
 * オブジェクトに関するユーティリティ群
 */
export const ObjectUtils = {
    /**
     * 二つの値が等価かどうか判定します。
     *
     * - オブジェクトだった場合、全ての列挙可能メンバーが等しいかを再帰的に確認
     *   します。
     * - 循環参照がある場合はスタックオーバーフローします。
     *
     * @param x 比較する値1
     * @param y 比較する値2
     * @returns 判定結果
     */
    deepEqual(x: unknown, y: unknown): boolean {
        if (Array.isArray(x)) {
            if (!Array.isArray(y)) {
                return false;
            }

            const length = x.length;
            if (length !== y.length) {
                return false;
            }

            for (let i = 0; i < length; ++i) {
                if (!ObjectUtils.deepEqual(x[i], y[i])) {
                    return false;
                }
            }

            return true;
        }

        if (ObjectUtils.isObject(x)) {
            if (!ObjectUtils.isObject(y)) {
                return false;
            }

            const keys = Object.keys(x);
            if (keys.length !== Object.keys(y).length) {
                return false;
            }

            for (const key of keys) {
                if (!ObjectUtils.deepEqual(x[key], y[key])) {
                    return false;
                }
            }

            return true;
        }

        return x === y;
    },

    /**
     * 指定した値がオブジェクトかどうか判定します。
     *
     * @param x 検査する値
     * @returns `x` がオブジェクトであれば真、それ以外は偽。
     */
    isObject(
        this: void,
        x: unknown,
    ): x is Record<number | string | symbol, unknown> {
        return typeof x === "object" && x !== null;
    },

    /**
     * 指定した値が非 nullable 値かどうか判定します。
     *
     * @param x 検査する値
     * @returns `x` が非 nullable 値であれば真、それ以外は偽。
     */
    isNotNullable<T>(this: void, x: T): x is Exclude<T, null | undefined> {
        return x !== null && x !== undefined;
    },
};
